import React from "react";

import { fileUrl } from "../Tools/APIs/useMyAPI";

const ImagesGrid = (data) => {

  let dataAllOverview = data?.data?.data?.data?.overview



 
  return (
    <>
      <div className="max-md:hidden flex w-[95%] mx-auto justify-center">
        <div className="flex items-center">
          <img src={fileUrl + dataAllOverview?.image1} alt="" className="w-[90%] " />
        </div>
        <div className="flex flex-col">
          <img src={fileUrl + dataAllOverview?.image2} alt="" className="w-[90%] " />
          <img src={fileUrl + dataAllOverview?.image3} alt="" className="w-[90%] mt-5" />
        </div>
        <div className="flex">
          <img src={fileUrl + dataAllOverview?.image4} alt="" className="w-[90%] " />
        </div>
        <div className="flex flex-col">
          <img src={fileUrl + dataAllOverview?.image5} alt="" className="w-[90%] " />
          <img src={fileUrl + dataAllOverview?.image6} alt="" className="w-[90%] mt-5" />
        </div>
        <div className="flex items-center">
          <img src={fileUrl + dataAllOverview?.image7} alt="" className="w-[90%] " />
        </div>
      </div>
      {/* <div className="md:hidden flex flex-col w-[90%] mx-auto items-center justify-center">
        <div className="flex">
          <div className="flex flex-col">
            <img src={image2} alt="" />
            <img src={image3} alt="" />
          </div>
          <div className="flex">
            <img src={image4} alt="" />
          </div>
          <div className="flex flex-col">
            <img src={image5} alt="" />
            <img src={image6} alt="" />
          </div>
        </div>
        <div className="flex w-full">
          <img src={image1} alt="" className="w-1/2" />
          <img src={image7} alt="" className="w-1/2" />
        </div>
      </div> */}
    </>
  );
};

export default ImagesGrid;
