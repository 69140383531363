import React from "react";
import TitleSection from "./components/TitleSection";
import Slider from "./components/Swiper/Slider";
import Slide from "./components/Swiper/Slide";
import swiper1 from "./assets/swiper1.svg";
import { content, language } from "./Translation/translate";
import { fileUrl, useFETCH } from "./Tools/APIs/useMyAPI";

const Courses = ({data}) => {


  let dataAllCourses = data?.data?.data?.courses

  let slider=  data?.data?.data?.courses?.data;

  console.log(data) 

  
  return (


    <section id="courses" className=" flex flex-col gap-20">
      <TitleSection title={content.Courses} desc={dataAllCourses?.header} />
      <Slider data={data} />


      <div className="flex flex-col gap-5 md:hidden">
      { slider?.map((e) => (
        <Slide
          img={fileUrl +  e.image}
          title={e.name }
          desc={e.description}
          numOfLessons="12"
        />
      ))}

      </div>
    </section>
  );
};

export default Courses;
