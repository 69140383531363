import React from "react";

const Service = ({ img, title, desc }) => {
  return (
    <div className="w-full flex gap-5 justify-center">
      <div className=" p-4 w-fit h-fit bg-gradient-to-tr from-[#001B34] to-[#00386C] rounded-full">
        <img src={img} alt="" width={50} />
      </div>
      <div className="flex flex-col gap-3 w-[70%]">
        <h1 className="text-[#1F1F1F] font-semibold text-2xl">{title}</h1>
        <p className="text-[#1F1F1F] text-lg">{desc}</p>
      </div>
    </div>
  );
};

export default Service;
