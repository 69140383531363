import React, { useEffect } from "react";
import TitleSection from "../components/TitleSection";
import { FaPhoneAlt } from "react-icons/fa";
import { TbMailFilled } from "react-icons/tb";
import Input from "./Input";
import { content } from "../Translation/translate";
import { useFETCH, usePOST } from "../Tools/APIs/useMyAPI";

const ContactUs = ({data}) => {
  // const {data} = useFETCH ("contact-messages")
  // console.log(data)


  let dataAllFooter = data?.data?.data?.contact

  console.log(data)


  const {
    handleChangeInput,
    handleSubmit
  } = usePOST();
  

  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("contact-messages");
  };

 

  return (
    <section id="contact-us" className="flex flex-col gap-20">
      <TitleSection title={content.ContactUs} />
      <div className="flex flex-col gap-3 items-center w-[90%] mx-auto flex-wrap">
        <div className="flex gap-2 justify-center">
          <FaPhoneAlt size={25} color="#696969" />
          <p className="text-lg max-sm:text-sm break-all">{dataAllFooter?.phone}</p>
        </div>
        <div className="flex gap-2 justify-center">
          <TbMailFilled size={25} color="#696969" />
          <p className="text-lg max-sm:text-sm break-all">
            {dataAllFooter?.email}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-[70%] mx-auto">
        <Input name={"name"} onChange={handleChangeInput} type="text" placeholder={content.Name} />
        <Input name={"email"} onChange={handleChangeInput} type="email" placeholder={content.Email} />
        <Input name={"phone"} onChange={handleChangeInput} type="number" placeholder={content.PhoneNumber} />
        <textarea
          name="message"
          cols={10}
          onChange={handleChangeInput}
          rows={7}
          className="text-[#828282] p-4 outline-none border border-gray-300 rounded-xl"
          placeholder={content.Message}
        />
        <button onClick={handleSubmitMain} className="bg-gradient-to-tr from-[#001B34] to-[#00386C] rounded-xl text-white text-center font-bold w-full p-6 text-2xl max-md:text-xl">
          {content.Send}
        </button>
      </div>
    </section>
  );
};

export default ContactUs;
