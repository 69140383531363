import React from "react";
import Folder from "./components/Folder";
import icon1 from "./assets/Icon1.svg";
import icon2 from "./assets/Icon2.svg";
import icon3 from "./assets/Icon3.svg";
import TitleSection from "./components/TitleSection";
import { content, language } from "./Translation/translate";
import { fileUrl, useFETCH } from "./Tools/APIs/useMyAPI";

const Sections = ({ data }) => {
  let dataAllSection = data?.data?.data?.sections;

  console.log(data);

  return (
    <section id="sections" className="flex flex-col gap-20">
      <TitleSection title={content.Sections} desc={dataAllSection?.header} />
      <div className="flex justify-between items-center w-[90%] mx-auto flex-wrap gap-5 ">
        {dataAllSection?.data?.map((e) => (
          <>
            <Folder
              icon={ fileUrl + e.image}
              title={e.name}
              desc={e.description}
            />
          </>
        ))}

        {/* <Folder
          icon={icon2}
          title={content.Courses}
          desc={dataAllSection?.courses}
        />
        <Folder
          icon={icon3}
          title={content.Bachelor  }
          desc={dataAllSection?.bachelor }
        /> */}
      </div>
    </section>
  );
};

export default Sections;
