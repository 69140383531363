import React from "react";
import logo from "../assets/mawaheb.svg";
import { content, language } from "../Translation/translate";

const Footer = ({data}) => {

  let dataAllFooter = data?.data?.data?.contact
  

  const list1 = [
    [`${content.ForContact}`],
    `${dataAllFooter?.email}`,
    `${dataAllFooter?.phone}`,
  ];
  const list2 = [
    { name: content.Instructors, link: "#instructors" },
    { name: content.Overview, link: "#overview" },
  ];
  const list3 = [
    { name: content.Home, link: "#" },
    { name: content.Sections, link: "#sections" },
    { name: content.Courses, link: "#courses" },
  ];

  return (
    <footer
      className={`px-20 py-3 ${
        language === "ar" ? "footer-background-ar" : "footer-background-en"
      }  w-full flex flex-col gap-10`}
    >
      <div className="flex justify-start">
        <img src={logo} alt="" width={200} />
      </div>
      <div className="flex gap-20 justify-center max-md:justify-around text-[#1F1F1F] flex-wrap">
        <ul className="text-center space-y-1">
          {list2.map((e) => (
            <li className="text-lg font-semibold">
              <a href={e.link}>{e.name}</a>
            </li>
          ))}
        </ul>
        <ul className="text-center space-y-1">
          {list3.map((e) => (
            <li className="text-lg font-semibold">
              <a href={e.link}>{e.name}</a>
            </li>
          ))}
        </ul>
        <ul className="text-center space-y-1">
          {list1.map((e) => (
            <li className="text-lg font-semibold break-all">{e}</li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center">
        <p className="text-[#1F1F1F]0 text-center">
          Copyright @ 2023 .All Right Reservedm Developed and desigend by{" "}
          <span className="font-bold">ICR</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
