import React from "react";
import NavBar from "./layout/NavBar";
import Feature1 from "./assets/Feature1.svg";
import Feature2 from "./assets/Feature2.svg";
import Feature3 from "./assets/Feature3.svg";
import circle from "./assets/circle.svg";
import { content, language } from "./Translation/translate";
import { useFETCH } from "./Tools/APIs/useMyAPI";

const Hero = ({data}) => {


  let dataAllHero = data?.data?.data?.hero;

 
 

  return (
    <div
      className={`relative pb-11 overflow-hidden ${
        language === "ar" ? "hero-background-ar" : "hero-background-en"
      } `}
    >
      <img
        src={circle}
        alt=""
        className={`absolute ${
          language === "ar" ? "left-0 rotate-180" : "right-0"
        } top-1/2 z-[-1]`}
      />
      <img
        src={circle}
        alt=""
        className={`absolute ${
          language === "ar" ? "right-0" : "left-0 rotate-180"
        } top-1/3 z-[-1]`}
      />
      <img
        src={circle}
        alt=""
        className={`absolute ${
          language === "ar" ? "left-0 rotate-180" : "right-0 "
        } -top-20 z-[-1]`}
      />
      <NavBar />
      <div className="flex flex-col  justify-center items-center gap-5 w-[90%] mx-auto">
        <h1 className="text-7xl max-md:text-5xl text-[#005BAF] font-semibold text-center">
          {content.HeroTitle}
        </h1>
        <p className="text-4xl max-md:text-2xl text-[#403F3F] w-2/3 text-center leading-relaxed">
          {dataAllHero?.description}
        </p>
      </div>
      <div className="flex justify-center items-center gap-10 mt-32 w-[95%] mx-auto flex-wrap">
        <div className="flex gap-4 w-[30%] max-md:w-[45%] max-sm:w-[90%] mx-auto justify-center">
          <img src={Feature1} alt="" className="max-md:w-[35%]" />
          <div className="flex flex-col gap-3 justify-center">
            <h2 className="text-[#5C5C5C] font-bold text-3xl max-sm:text-2xl max-md:text-xl">
              {dataAllHero?.hours_count}
            </h2>
            <p className="text-[#454545] font-semibold text-lg max-sm:text-sm text-center">
              {content.HoursContent}
            </p>
          </div>
        </div>
        <div className="flex gap-4 w-[30%] max-md:w-[45%] max-sm:w-[90%] mx-auto justify-center">
          <img src={Feature2} alt="" className="max-md:w-[35%]" />

          <div className="flex flex-col gap-3 justify-center">
            <h2 className="text-[#5C5C5C] font-bold text-3xl max-sm:text-2xl max-md:text-xl">
              {dataAllHero?.students_count}
            </h2>
            <p className="text-[#454545] font-semibold text-lg max-sm:text-sm text-center">
              {content.Students}
            </p>
          </div>
        </div>
        <div className="flex gap-4 w-[30%] max-md:w-[45%] max-sm:w-[90%] mx-auto justify-center">
          <img src={Feature3} alt="" className="max-md:w-[35%]" />
          <div className="flex flex-col gap-3 justify-center">
            <h2 className="text-[#5C5C5C] font-bold text-3xl max-sm:text-2xl max-md:text-xl">
              {dataAllHero?.courses_count}
            </h2>
            <p className="text-[#454545] font-semibold text-lg max-sm:text-sm text-center">
              {content.Courses}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
