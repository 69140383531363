import React from "react";
import { language } from "../Translation/translate";

const Folder = ({ title, desc, icon }) => {
  return (
    <div className="flex flex-col gap-4 relative bg-[#BAD5ED] rounded-lg shadow-lg py-4 px-10 w-[30%] max-md:w-[95%] mx-auto max-md:mb-10 my-5 ">
      <div
        className={`absolute  -top-8 ${
          language === "ar" ? "right-0" : "left-0"
        }  h-10 w-[30%] bg-[#BAD5ED] rounded-t-xl `}
      >
        <div className="relative">
          <span
            className={`absolute top-0 ${
              language === "ar" ? "-left-8 -rotate-90" : "-right-8"
            }  file`}
          ></span>
        </div>
      </div>
      <div
        className={`absolute -top-5 ${
          language === "ar" ? "right-1" : "left-1"
        }  aspect-square size-16`}
      >
        <img src={icon} alt="" className="w-full h-full" />
      </div>
      <h1
        className={`text-[#282828] text-2xl font-semibold ${
          language === "ar" ? "mr-[15%]" : "ml-[15%]"
        } `}
      >
        {title}
      </h1>
      <p className="text-[#282828] mx-auto text-sm">{desc}</p>
    </div>
  );
};

export default Folder;
