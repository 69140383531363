import React, { useEffect, useRef, useState } from "react";
import { IoMdPlay } from "react-icons/io";
import { FaStar } from "react-icons/fa6";

const Slide = ({ title, desc, img, numOfLessons, rating }) => {
  return (
    <div className="flex  gap-3 border-2 border-[#001E3B] rounded-2xl w-[90%] md:h-[200px] mx-auto max-sm:flex-col">
      <img src={img} alt="" className="object-cover rounded-2xl md:!w-[30%]" />
      <div className="flex  flex-col justify-around p-3 gap-4 flex-1">
        <h1 className="text-4xl font-semibold text-[#1B1B1C] text-start">
          {title}
        </h1>
        <p className="text-[#000000B2] text-lg font-semibold text-start h-[40%] text-ellipsis">
          {desc?.length > 80 ? desc.substring(0, 80) + "..." : desc}
        </p>
        <div className="flex justify-end px-2">
          <div className="flex gap-3">
            <p className="text-[#7F8185] text-lg">{numOfLessons} Lessons</p>
            <div className="p-2 bg-[#6A6974] flex justify-center items-center rounded-full">
              <IoMdPlay color="#E2E0E3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
