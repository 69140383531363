import React from "react";

const ButtonLink = ({ Icon, store, title , link }) => {
  return (
    <a href={link} target="_blank">
      <div className="bg-white flex justify-center items-center gap-2 px-8 py-1 rounded-lg max-sm:px-5">
      <Icon color="black" size={30} />
      <div className="flex flex-col">
        <p className="font-semibold max-sm:text-sm">{title}</p>
        <p className="font-bold text-lg max-sm:text-sm">{store}</p>
      </div>
    </div>
    </a>
  );
};

export default ButtonLink;
