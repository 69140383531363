import Translate from "./translate.json";

export const language = window.localStorage.getItem("language");

export let direction = language === "ar" ? "rtl" : "ltr";

export let content = {};

export const changeLanguage = (lang) => {
  window.localStorage.setItem("language", lang);
  window.location.reload(false);
};

if (language === "ar") {
  content = Translate.ar;
  document.body.style.direction = "rtl";
} else {
  content = Translate.en;
  document.body.style.direction = "ltr";
}
