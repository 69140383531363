import React, { useState } from "react";
import mobile from "../assets/mobile.svg";
import ButtonLink from "./ButtonLink";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { content, language } from "../Translation/translate";
import { GrDownload } from "react-icons/gr";
import axios from "axios";
import { baseUrl } from "../Tools/APIs/useMyAPI";
import { toast } from "react-toastify";
import { Button, Progress } from "@nextui-org/react";
import { LuDownload } from "react-icons/lu";

const MobileApp = ({ data }) => {
  let dataAllApplication = data?.data?.data?.application;

  const filePath = dataAllApplication?.file;

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async () => {
    const file_name = "تطبيق مواهب";
    const apiEndpoint = `${baseUrl}admin/storage/download`;

    if (!filePath) {
      toast.error("المسار غير متوفر!");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(apiEndpoint, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: { file_path: filePath, file_name: file_name },
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1)
          );
          setProgress(percentCompleted);
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("تم التحميل بنجاح!");
    } catch (error) {
      console.error("حدث خطأ أثناء تحميل الملف:", error);
      toast.error("فشل التحميل!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative md:h-[300px] max-md:py-10 px-10 flex justify-center max-md:flex-col items-center gap-4 max-md:gap-10 bg-gradient-to-tr from-[#001B34] to-[#00386C]">
      <div className="w-[27%] max-md:w-[90%] max-md:text-center">
        <h1 className="text-white font-semibold text-4xl">
          {dataAllApplication?.description}
        </h1>
      </div>
      <div className="flex gap-2 flex-1 items-center justify-end max-md:flex-col">
        <div className="w-[40%] max-md:w-full">
          <img
            src={mobile}
            alt=""
            className={`${
              language === "ar" ? "scale-x-[-1]" : ""
            } md:hidden w-[300px]`}
          />
        </div>
        <div className="flex flex-col items-center max-lg:items-end max-xl:items-end gap-7">
          <h2 className="font-semibold max-md:text-2xl text-white w-full text-center">
            {content.DownloadHere}
          </h2>
          <div className="flex gap-3 flex-wrap justify-end max-md:flex-col">
            <ButtonLink
              link={dataAllApplication?.app_store}
              title="Download on the"
              store="App Store"
              Icon={FaApple}
            />
            <ButtonLink
              link={dataAllApplication?.google_play}
              title="Android App On"
              store="Google Play"
              Icon={FaGooglePlay}
            />
          </div>
          <div className=" ">
            <Button
              onPress={handleDownload}
              disabled={isLoading}
              className="font-semibold !bg-white text-xl flex items-center gap-2 px-9 py-7 max-md:py-7 max-md:px-10"
            >
              {isLoading ? (
                "جاري التحميل..."
              ) : (
                <>
                  <LuDownload size={35} className="text-black mt-1" />
                  <div className="flex flex-col justify-center items-center">
                    <div className="text-base max-md:text-base">
                      {" "}
                      Download with{" "}
                    </div>
                    <h1 className="font-bold  max-md:text-lg">
                      {" "}
                      direct link{" "}
                    </h1>
                  </div>
                </>
              )}
            </Button>
            {isLoading && (
              <div>
                <Progress
                  aria-label="Downloading..."
                  size="md"
                  value={progress}
                  color={progress === 100 ? "success" : "primary"}
                  showValueLabel={true}
                  className="max-w-md text-white font-bold"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <img
        src={mobile}
        alt=""
        className={`absolute ${
          language === "ar"
            ? "left-1/2 -translate-x-[45%] scale-x-[-1]"
            : "right-1/2 translate-x-[45%]"
        } w-[45%] max-w-[400px] max-md:hidden`}
      />
    </div>
  );
};

export default MobileApp;
