import React from "react";

const TitleSection = ({ title, desc }) => {
  return (
    <div className="flex flex-col gap-7 text-center w-[90%] mx-auto">
      <h1 className="text-6xl text-[#005BAF] max-md:text-5xl">{title}</h1>
      <p className="text-xl text-[#0D171CB2] max-md:text-lg leading-9">
        {desc}
      </p>
    </div>
  );
};

export default TitleSection;
