import React from "react";

import { fileUrl } from "./Tools/APIs/useMyAPI";


const Video = ({video}) => {

  // let video =  data?.data?.hero?.video

  return (
    <div className="w-[90%] mx-auto ">
      <video
        muted
        controls
        className="mx-auto shadow-xl shadow-black/20 rounded-[15px]"
      >
        <source  src={(fileUrl + video)}   />
        </video>
    </div>
  );
};

export default Video;
