import React from "react";
import TitleSection from "../components/TitleSection";
import Instructor from "./Instructor";
import instructorImage from "../assets/instructor.svg";
import { content, language } from "../Translation/translate";
import { fileUrl, useFETCH } from "../Tools/APIs/useMyAPI";

const Instructors = ({data}) => {
 

  let dataAllTeacher = data?.data?.data?.instructors

  console.log(dataAllTeacher)

  console.log(data)


  return (
    <section id="instructors" className="flex flex-col gap-20">
      <TitleSection
        title={content.Instructors}
        desc={dataAllTeacher?.header }
      />
      <div className="flex justify-center w-[90%] mx-auto gap-10 flex-wrap mt-32">
        {dataAllTeacher?.data?.map((e)=>(
        <Instructor img={fileUrl + e.image} />
        ))}
        {/* <Instructor img={fileUrl + dataAllTeacher?.image_teacher2} />
        <Instructor img={fileUrl + dataAllTeacher?.image_teacher3} /> */}
      </div>
    </section>
  );
};

export default Instructors;
