import React from "react";
import TitleSection from "../components/TitleSection";
import ImagesGrid from "./ImagesGrid";
import Service from "./Service";
import certificate from "../assets/certificate.svg";
import profile from "../assets/profile.svg";
import playLesson from "../assets/play-lesson.svg";
import { content, language } from "../Translation/translate";
import { useFETCH } from "../Tools/APIs/useMyAPI";

const Overview = ({data}) => {



  let dataAllOverview = data?.data?.data?.overview



  return (
    <section id="overview" className="flex flex-col gap-20">
      <TitleSection title={content.Overview} desc={dataAllOverview?.description} />
      <ImagesGrid data={data} />
      <div className="flex w-[90%] mx-auto justify-between flex-wrap gap-10">
        <div className="w-[45%] max-md:w-[95%] max-md:mx-auto">
          <Service
            img={certificate}
            title={content.OnlineDegrees }
            desc={dataAllOverview?.online_degrees}
          />
        </div>
        <div className="w-[45%] max-md:w-[95%] max-md:mx-auto">
          <Service
            img={playLesson}
            title={content.ShortCourses}
            desc={dataAllOverview?.short_courses}
          />
        </div>
        <div className="w-[45%] max-md:w-[95%] mx-auto">
          <Service
            img={profile}
            title={content.ProfessionalInstructors}
            desc={dataAllOverview?.professional_instructors}
          />
        </div>
      </div>
    </section>
  );
};

export default Overview;
