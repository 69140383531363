import React, { useEffect, useState } from "react";
import logo from "../assets/mawaheb.svg";
import { IoMenu } from "react-icons/io5";
import Switch from "react-switch";
import { changeLanguage, content, language } from "../Translation/translate";

const sections = [
  { name: "home", link: "#", label: content.Home },
  { name: "sections", link: "#sections", label: content.Sections },
  { name: "courses", link: "#courses", label: content.Courses },
  { name: "overview", link: "#overview", label: content.Overview },
  { name: "instructors", link: "#instructors", label: content.Instructors },
];

const NavBar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    if (windowWidth >= 768) setisOpen(false);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  const [isOpen, setisOpen] = useState(false);
  const [section, setSection] = useState({
    home: true,
    sections: false,
    courses: false,
    overview: false,
    instructors: false,
  });

  const handleChangeSection = (name) => {
    for (const [key, value] of Object.entries(section)) {
      if (key !== name) setSection((prev) => ({ ...prev, [key]: false }));
      else setSection((prev) => ({ ...prev, [key]: true }));
    }
  };

  return (
    <nav className="flex max-md:gap-0 justify-between items-center p-10 flex-wrap max-lg:p-5">
      <div className="relative flex justify-center items-center">
        <div
          className={`bg-white absolute -top-24 -left-20 rounded-3xl ${
            language === "ar" ? "-rotate-[15deg]" : "rotate-[15deg]"
          }  z-[-1] w-[400px] h-[300px] max-lg:w-[300px] max-md:w-[250px] max-lg:h-[250px] max-md:h-[200px]`}
        />
        <div className="max-lg:w-[160px] max-lg:h-[80px] max-md:w-[130px] max-md:h-[100]">
          <a href="/">
            <img src={logo} alt="hello" className="w-full h-full" />
          </a>
        </div>
      </div>
      <ul className="flex items-center max-md:hidden">
        {sections.map((e) => (
          <li
            key={e.name}
            onClick={() => handleChangeSection(e.name)}
            className={`${
              section[e.name]
                ? "bg-gradient-to-tr from-[#00386C] to-[#001B34] text-white"
                : "text-[#303030]"
            } text-lg rounded-lg flex items-center justify-center `}
          >
            <a
              href={e.link}
              className=" px-6 py-2 font-semibold max-lg:text-base max-lg:px-3 max-lg:py-1"
            >
              {e.label}
            </a>
          </li>
        ))}
      </ul>
      <label className="flex items-center gap-1 max-md:hidden">
        <div className={` text-black font-bold `}>
          {language === "ar" ? "EN" : "عربي"}
        </div>
        <Switch
          height={15}
          uncheckedIcon
          checkedIcon
          offColor="#aaa"
          onColor="#aaa"
          offHandleColor={"#005BAF"}
          onHandleColor={"#005BAF"}
          activeBoxShadow={`0 0 1px 1px ${"#005BAF"}`}
          handleDiameter={22}
          width={35}
          onChange={(e) => {
            if (e) changeLanguage("en");
            else changeLanguage("ar");
          }}
          checked={language === "en" ? true : false}
        />
        <div className={`text-black font-bold`}>
          {language === "ar" ? "عربي" : "EN"}
        </div>
      </label>
      <button
        onClick={() => setisOpen(!isOpen)}
        className="md:hidden flex justify-end"
      >
        <IoMenu size={30} color="#00488B" />
      </button>
      {isOpen && (
        <ul className="w-full space-y-1">
          {sections.map((e) => (
            <li
              onClick={() => handleChangeSection(e.name)}
              className={`${
                section[e.name]
                  ? "bg-gradient-to-tr from-[#00386C] to-[#001B34] text-white"
                  : "text-[#3E3E3E]"
              } text-xl rounded-lg flex items-center justify-center text-center border-b-2 border-[#3E3E3E]`}
            >
              <a href={e.link} className="py-1 max-lg:text-lg w-full">
                {e.label}
              </a>
            </li>
          ))}
          <li className="rounded-lg flex items-center justify-center text-center border-b-2 border-[#3E3E3E] py-1">
            <label className="flex items-center gap-1">
              <div className={` text-black font-bold `}>
                {language === "ar" ? "EN" : "عربي"}
              </div>
              <Switch
                height={15}
                uncheckedIcon
                checkedIcon
                offColor="#aaa"
                onColor="#aaa"
                offHandleColor={"#005BAF"}
                onHandleColor={"#005BAF"}
                activeBoxShadow={`0 0 1px 1px ${"#005BAF"}`}
                handleDiameter={22}
                width={35}
                onChange={(e) => {
                  if (e) changeLanguage("en");
                  else changeLanguage("ar");
                }}
                checked={language === "en" ? true : false}
              />
              <div className={`text-black font-bold`}>
                {language === "ar" ? "عربي" : "EN"}
              </div>
            </label>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavBar;
