import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./styles.css";
import Slide from "./Slide";
import swiper1 from "../../assets/swiper1.svg";
import { language } from "../../Translation/translate";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";

const Slider = ({ data }) => {
  // const {data } = useFETCH(language === "ar" ? "infos?locale=ar" :"infos")

  let dataAllCourses = data?.data?.data?.courses?.data;

  return (
    <Swiper
      className="mySwiper max-md:!hidden"
      slidesPerView={1}
      spaceBetween={10}
      breakpoints={{
        1024: {
          slidesPerView: 2,
        },
      }}
    >
      { dataAllCourses?.map((e) => (
        <SwiperSlide>
          <Slide
            img={fileUrl + e.image}
            title={e.name}
            desc={e.description}
            numOfLessons={"56"}
           
          />
        </SwiperSlide>
      ))}
      {/* <SwiperSlide>
        <Slide
          img={fileUrl + dataAllCourses?.image_math}
          title={dataAllCourses?.courses_mathematics?.title}
          desc={dataAllCourses?.courses_mathematics?.description}
          numOfLessons={dataAllCourses?.courses_informatics?.lessons_count}
          rating="4.9"
        />
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <Slide
          img={fileUrl + dataAllCourses?.image_science}
          title={dataAllCourses?.courses_science?.title}
          desc={dataAllCourses?.courses_science?.description}
          numOfLessons={dataAllCourses?.courses_informatics?.lessons_count}
          rating="4.9"
        />
      </SwiperSlide> */}
    </Swiper>
  );
};

export default Slider;
