import React from "react";

const Input = ({ type, placeholder, name,onChange }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      className="p-4 text-[#828282] outline-none border border-gray-300 rounded-xl"
    />
  );
};

export default Input;
