import React from "react";

const Instructor = ({ img }) => {
  return (
    <div className="relative w-[28%] max-md:w-[45%] bg-[#D9D9D9] h-48 rounded-2xl max-md:mb-[15%] max-[445px]:w-[90%] mx-auto max-[445px]:mb-[25%]">
      <img
        src={img}
        alt=""
        className="absolute bottom-0 left-1/2 -translate-x-1/2"
      />
    </div>
  );
};

export default Instructor;
