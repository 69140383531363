import { ToastContainer } from "react-toastify";
import ContactUs from "./ContactUs/ContactUs";
import Courses from "./Courses";
import Hero from "./Hero";
import Instructors from "./Instructors/Instructors";
import Footer from "./layout/Footer";
import MobileApp from "./MobileApp/MobileApp";
import Overview from "./Overview/Overview";
import Sections from "./Sections";
import { fileUrl, useFETCH, useFilter } from "./Tools/APIs/useMyAPI";
import Loading from "./Tools/Loading/Loading";
import { language } from "./Translation/translate";
import Video from "./Video";

function App() {
  const { data, isLoading } = useFETCH(`home?local=${language === null ? "ar" : language}`);
  let video = data?.data?.data?.hero?.video;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
    <ToastContainer position="top-center" style={{zIndex:"9999999999"}} />
      <div className="App  flex-col gap-10 flex">
        <Hero data={data} />
        <Video video={video} />
        <Sections data={data} />
        <Courses data={data} />
        <Overview data={data} />
        <MobileApp data={data} />
        <Instructors data={data} />
        <ContactUs data={data} />
        <Footer data={data} />
      </div>
    </>
  );
}

export default App;
